import api from "@/base/utils/request";

// 获取详情
export const getProjectInfo = data => {
  return api({
    url: "/admin/cyc/Settings/getCycProjectInfoConfig",
    method: "post",
    data
  });
};

// 保存详情
export const saveProjectInfo = data => {
  return api({
    url: "/admin/cyc/Settings/setCycProjectInfoConfig",
    method: "post",
    data
  });
};
export  const getCompanyPositions = data => {
  return api({
    url: "/admin/cyc/user/companyPositions",
    method: "post",
    data
  });
};